import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBaseV2 = _resolveComponent("FilterBaseV2")!

  return (_openBlock(), _createBlock(_component_FilterBaseV2, {
    id: "filter-competitors-list-page",
    title: "Filtros",
    subtitle: "Filtro Tela de Vendedores",
    activeModal: _ctx.isActiveModal,
    closeModal: _ctx.isCloseModal,
    showCompanyField: true,
    showStoreField: true,
    displayAllCompanies: false,
    displayAllStores: true,
    hideFilter: false,
    staticModal: true,
    hideClose: false,
    disabledClick: false,
    disabled: false,
    showGoToBack: false,
    "onWatch:changesGlobalFields": _cache[0] || (_cache[0] = (data) => _ctx.handleChangesGlobalFields(data)),
    "onWatch:requestFlow": _cache[1] || (_cache[1] = (data) => _ctx.handleRequestFlow(data))
  }, null, 8, ["activeModal", "closeModal"]))
}